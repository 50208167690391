import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-100 dark:bg-gray-800 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* 左侧公司信息 */}
          <div className="text-gray-600 dark:text-gray-400 mb-4 md:mb-0">
            {t('footer.companyInfo')}
          </div>
          
          {/* 中间 Product Hunt Badge */}
          <div className="mb-4 md:mb-0">
            <a 
              href="https://www.producthunt.com/posts/web3insure?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-web3insure" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-block"
            >
              <img 
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=571094&theme=light" 
                alt="web3Insure - A REAL Decentralized Web3 Insurance | Product Hunt" 
                width="250" 
                height="54" 
                className="dark:hidden" // 在深色模式下隐藏浅色主题徽章
              />
              <img 
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=571094&theme=dark" 
                alt="web3Insure - A REAL Decentralized Web3 Insurance | Product Hunt" 
                width="250" 
                height="54" 
                className="hidden dark:block" // 在浅色模式下隐藏深色主题徽章
              />
            </a>
          </div>

          {/* 右侧版权信息 */}
          <div className="text-gray-600 dark:text-gray-400">
            © 2024 Web3Insure
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;