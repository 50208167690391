import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wallet } from 'lucide-react';
import { useUser, UserButton, useClerk } from '@clerk/clerk-react';
import Logo from '../../logo/web3insure-logo.svg';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useUser();
  const { openSignIn } = useClerk();

  const handleSignIn = () => {
    openSignIn({
      redirectUrl: '/',
      appearance: {
        elements: {
          formButtonPrimary: 'bg-blue-600 hover:bg-blue-700',
          footerActionLink: 'text-blue-600 hover:text-blue-700'
        }
      }
    });
  };

  return (
    <header className="bg-gradient-to-r from-blue-50 to-blue-100 text-gray-800 py-4 shadow-sm dark:from-gray-800 dark:to-gray-700 dark:text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-shrink-0">
            <img src={Logo} alt="Web3Insure Logo" className="w-8 h-8 mr-2" />
            <h1 className="text-xl sm:text-2xl font-bold">{t('projectName')}</h1>
          </div>
          <nav>
            {!isSignedIn ? (
              <button 
                onClick={handleSignIn}
                className="inline-flex items-center px-4 py-2 rounded-full bg-blue-50 text-blue-600 hover:bg-blue-100 transition-colors duration-200 dark:bg-blue-900 dark:text-blue-300 dark:hover:bg-blue-800"
              >
                <span className="mr-2 text-sm sm:text-base font-medium">
                  {t('wallet.connectButton')}
                </span>
                <Wallet className="w-4 h-4" />
              </button>
            ) : (
              <UserButton afterSignOutUrl="/" />
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;