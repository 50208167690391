import React from 'react';
import { useTranslation } from 'react-i18next';
import mediumPosts from '../../mediumPostsConfig.json';

const BlogList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center dark:text-white mb-12">
          {t('blogList.title')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {mediumPosts.map((post, index) => (
            <a
              key={index}
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block bg-white p-6 rounded-lg shadow-md dark:bg-gray-700 hover:shadow-lg transition-shadow"
            >
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover mb-4 rounded" />
              <h3 className="text-xl font-semibold mb-2 dark:text-white">{post.title}</h3>
              <p className="text-gray-600 dark:text-gray-300">{post.description}</p>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogList; 