import React from 'react';
import { useTranslation } from 'react-i18next';
import { Coins, Users, Shield, TrendingUp, PieChart, Coins as Token } from 'lucide-react';

const ValuePropositions: React.FC = () => {
  const { t } = useTranslation();

  const sections = [
    {
      icon: Coins,
      title: t('value.investors.title'),
      intro: t('value.investors.intro'),
      points: [
        {
          icon: TrendingUp,
          title: t('value.investors.points.returns.title'),
          content: t('value.investors.points.returns.content')
        },
        {
          icon: PieChart,
          title: t('value.investors.points.profit.title'),
          content: t('value.investors.points.profit.content')
        },
        {
          icon: Token,
          title: t('value.investors.points.token.title'),
          content: t('value.investors.points.token.content')
        }
      ]
    },
    {
      icon: Users,
      title: t('value.insureds.title'),
      intro: t('value.insureds.intro'),
      points: [
        {
          icon: Coins,
          title: t('value.insureds.points.value.title'),
          content: t('value.insureds.points.value.content')
        },
        {
          icon: Shield,
          title: t('value.insureds.points.trust.title'),
          content: t('value.insureds.points.trust.content')
        },
        {
          icon: TrendingUp,
          title: t('value.insureds.points.stability.title'),
          content: t('value.insureds.points.stability.content')
        },
        {
          icon: Users,
          title: t('value.insureds.points.inclusive.title'),
          content: t('value.insureds.points.inclusive.content')
        }
      ]
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-blue-50 to-white dark:from-gray-800 dark:to-gray-900">
      <div className="container mx-auto px-4">
        {sections.map((section, index) => (
          <div key={index} className="mb-20 last:mb-0">
            <div className="flex items-center justify-center mb-6">
              <section.icon className="w-12 h-12 text-blue-600 dark:text-blue-400" />
              <h2 className="text-3xl md:text-4xl font-bold ml-4 dark:text-white">
                {section.title}
              </h2>
            </div>
            <p className="text-lg text-center mb-8 text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              {section.intro}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {section.points.map((point, idx) => (
                <div key={idx} className="bg-white p-6 rounded-lg shadow-md dark:bg-gray-700">
                  <div className="flex items-center mb-4">
                    <point.icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mr-2" />
                    <h3 className="text-lg font-semibold dark:text-white">
                      {point.title}
                    </h3>
                  </div>
                  <p className="dark:text-gray-300">{point.content}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ValuePropositions; 