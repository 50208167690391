import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { Settings } from 'lucide-react';

const OperatingModel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="operating-model" className="py-20 bg-gradient-to-b from-gray-100 to-gray-300 dark:from-gray-800 dark:to-gray-900">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center mb-8">
          <Settings className="w-12 h-12 text-blue-600 dark:text-blue-400 mr-4" />
          <h2 className="text-3xl md:text-4xl font-bold text-center dark:text-white">
            {t('operatingModel.title')}
          </h2>
        </div>
        <div className="flex justify-center w-full">
          <Zoom>
            <img
              src="/operating-model.svg"
              alt="Operating Model"
              className="w-full h-auto"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Zoom>
        </div>
      </div>
    </section>
  );
};

export default OperatingModel;