import React from 'react';
import { useTranslation } from 'react-i18next';
import { Database, Users, DollarSign, TrendingUp } from 'lucide-react';

const DataMetrics: React.FC = () => {
  const { t } = useTranslation();
  
  const metrics = [
    { 
      icon: Database,
      title: t('metrics.saas.title'),
      value: '50,000+',
      description: t('metrics.saas.description')
    },
    { 
      icon: Users,
      title: t('metrics.network.title'),
      value: '1M+',
      description: t('metrics.network.description')
    },
    { 
      icon: DollarSign,
      title: t('metrics.impact.title'),
      value: '$10B+',
      description: t('metrics.impact.description')
    }
  ];

  return (
    <section className="py-20 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center mb-4">
          <TrendingUp className="w-12 h-12 text-blue-600 dark:text-blue-400 mr-4" />
          <h2 className="text-3xl md:text-4xl font-bold text-center dark:text-white">
            {t('metrics.title')}
          </h2>
        </div>
        <p className="text-lg text-center mb-12 text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
          {t('metrics.introduction')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {metrics.map((metric, index) => (
            <div key={index} className="text-center p-6 rounded-lg bg-blue-50 dark:bg-gray-800">
              <metric.icon className="w-12 h-12 mx-auto mb-4 text-blue-600 dark:text-blue-400" />
              <div className="text-3xl font-bold mb-2 text-blue-600 dark:text-blue-400">
                {metric.value}
              </div>
              <h3 className="text-xl font-semibold mb-2 dark:text-white">
                {metric.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {metric.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DataMetrics; 