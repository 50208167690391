import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { supabase } from '../supabaseClient';
import { ClipboardList } from 'lucide-react';

const WaitlistForm: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    email: '',
    phone_number: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'email') {
      setEmailError('');
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError('');
    setEmailError('');

    if (!validateEmail(formData.email)) {
      setEmailError(t('waitlist.form.emailError'));
      setIsSubmitting(false);
      return;
    }

    try {
      const { error } = await supabase
        .from('waitlist')
        .insert([formData]);

      if (error) throw error;

      setSubmitSuccess(true);
      setFormData({ name: '', organization: '', email: '', phone_number: '', message: '' });
    } catch (error) {
      console.error('Error submitting to waitlist:', error);
      setSubmitError(t('waitlist.errorMessage'));
    } finally {
      setIsSubmitting(false);
    }
  };

  if (submitSuccess) {
    return (
      <div className="text-center py-20 dark:bg-gray-800 dark:text-white">
        <h3 className="text-2xl md:text-3xl font-bold mb-4">{t('waitlist.successTitle')}</h3>
        <p className="text-lg">{t('waitlist.successMessage')}</p>
      </div>
    );
  }

  return (
    <section id="waitlist" className="py-20 bg-blue-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center mb-8">
          <ClipboardList className="w-12 h-12 text-blue-600 dark:text-blue-400 mr-4" />
          <h2 className="text-3xl md:text-4xl font-bold text-center dark:text-white">{t('waitlist.title')}</h2>
        </div>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2 dark:text-white">{t('waitlist.form.name')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="organization" className="block mb-2 dark:text-white">{t('waitlist.form.organization')}</label>
            <input
              type="text"
              id="organization"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 dark:text-white">{t('waitlist.form.email')}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={`w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600 ${emailError ? 'border-red-500' : ''}`}
            />
            {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="phone_number" className="block mb-2 dark:text-white">{t('waitlist.form.phoneNumber')}</label>
            <input
              type="tel"
              id="phone_number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mb-2 dark:text-white">{t('waitlist.form.message')}</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600"
              rows={4}
            ></textarea>
          </div>
          {submitError && <p className="text-red-500 mb-4">{submitError}</p>}
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 dark:bg-blue-700 dark:hover:bg-blue-600"
          >
            {isSubmitting ? t('waitlist.form.submitting') : t('waitlist.form.submit')}
          </button>
        </form>
      </div>
    </section>
  );
};

export default WaitlistForm;