import React from 'react';
import { useTranslation } from 'react-i18next';
import { BookOpen, FileText, TrendingUp } from 'lucide-react';

const IndustryInsights: React.FC = () => {
  const { t } = useTranslation();

  const insights = [
    {
      icon: BookOpen,
      title: t('insights.blockchain.title'),
      content: t('insights.blockchain.content'),
      source: t('insights.blockchain.source')
    },
    {
      icon: FileText,
      title: t('insights.smartContracts.title'),
      content: t('insights.smartContracts.content'),
      source: t('insights.smartContracts.source')
    },
    {
      icon: TrendingUp,
      title: t('insights.defi.title'),
      content: t('insights.defi.content'),
      source: t('insights.defi.source')
    }
  ];

  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center mb-4">
          <BookOpen className="w-12 h-12 text-blue-600 dark:text-blue-400 mr-4" />
          <h2 className="text-3xl md:text-4xl font-bold text-center dark:text-white">
            {t('insights.title')}
          </h2>
        </div>
        <p className="text-lg text-center mb-12 text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
          {t('insights.introduction')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {insights.map((insight, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md dark:bg-gray-700">
              <insight.icon className="w-12 h-12 text-blue-600 mb-4 dark:text-blue-400" />
              <h3 className="text-xl font-semibold mb-2 dark:text-white">
                {insight.title}
              </h3>
              <p className="text-gray-600 mb-4 dark:text-gray-300">
                {insight.content}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 italic">
                {insight.source}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IndustryInsights; 