import React from 'react';
import { useTranslation } from 'react-i18next';
import { Users } from 'lucide-react';

const TeamSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-16 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center mb-12">
          <Users className="w-12 h-12 text-blue-600 dark:text-blue-400 mr-4" />
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white">
            {t('team.title')}
          </h2>
        </div>

        {/* 标题和使命 */}
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-900 dark:text-white">
            {t('team.title')}
          </h2>
          <p className="text-lg text-gray-700 dark:text-gray-300 max-w-4xl mx-auto">
            {t('team.mission')}
          </p>
        </div>

        {/* 团队构成 */}
        <div className="mb-12 text-center">
          <h3 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
            {t('team.structure.title')}
          </h3>
          <p className="text-lg text-gray-700 dark:text-gray-300 max-w-3xl mx-auto">
            {t('team.structure.description')}
          </p>
        </div>

        {/* 匿名原因 */}
        <div>
          <h3 className="text-2xl font-semibold mb-8 text-center text-gray-900 dark:text-white">
            {t('team.anonymity.title')}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {['decentralization', 'focus', 'security', 'spirit'].map((reason) => (
              <div 
                key={reason}
                className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <h4 className="text-xl font-semibold mb-3 text-blue-600 dark:text-blue-400">
                  {t(`team.anonymity.reasons.${reason}.title`)}
                </h4>
                <p className="text-gray-700 dark:text-gray-300">
                  {t(`team.anonymity.reasons.${reason}.content`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection; 