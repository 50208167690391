import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './locales/en-US.json';
import zhCN from './locales/zh-CN.json';
import zhTW from './locales/zh-TW.json';
import fr from './locales/fr.json';
import ur from './locales/ur.json';
import am from './locales/am.json';
import pt from './locales/pt.json';
import ar from './locales/ar.json';
import tr from './locales/tr.json';
import fa from './locales/fa.json';
import es from './locales/es.json';
import ru from './locales/ru.json';

const resources = {
  'en-US': { translation: enUS },
  'zh-CN': { translation: zhCN },
  'zh-TW': { translation: zhTW },
  fr: { translation: fr },
  ur: { translation: ur },
  am: { translation: am },
  pt: { translation: pt },
  ar: { translation: ar },
  tr: { translation: tr },
  fa: { translation: fa },
  es: { translation: es },
  ru: { translation: ru },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;